// IMPORTING STYLES
import '../css/base.min.css';
// IMPORTING ELEMENTS
import {useEffect} from "react";
import {CardElement, Title} from "./Elements";

export function PageMenuHelpful(){
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        document.title = 'Актуальное';
        tg.BackButton.onClick((() => window.location.assign('/')));
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick(() => window.location.assign('/'));
    }, []);
    return(
        <div className="main-block">
            <Title title="Полезное"/>
            <CardElement image='/src/images/icons/help.png' title='HandBook'
                         subtitle='О компании и работе в ней' path='/helpful/handbook'/>
            <CardElement image='/src/images/icons/whiteleads.png' title='Ценности'
                         subtitle='Как работать эффективно' path='/helpful/handbook/worth'/>
            <CardElement image='/src/images/icons/employee.png' title='Сотрудники'
                         subtitle='Профили и структура' path='/helpful/employers'/>
            <CardElement image='/src/images/icons/helpful.png' title='Полезные материалы'
                         subtitle='Полезные материалы' path='/helpful/knowledge'/>
            <CardElement image='/src/images/icons/tools.png' title='Инструменты'
                         subtitle='Необходимое для работы' path='/helpful/tools'/>

        </div>
    );
}