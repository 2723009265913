import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PageIndex } from './modules/IndexPage';
import { PageMenuActual } from './modules/MenuActual';
import { PageMenuHelpful } from './modules/MenuHelpful';
import { PageMenuTools } from './modules/MenuTools';
import { PageMenuEmployers } from './modules/MenuEmployers';
import {Page404, PageError} from './modules/PageCodeState';
import { PageWorkInProgress } from './modules/PageCodeState';
import { PageMenuKnowlegde } from './modules/MenuLawFinance';
import { PageLawFinance } from './modules/PageLawFinance';
import { PageProfile } from './modules/ProfilePage';
import { PageProfileEdit } from './modules/ProfileEditPage';
import { PageNews } from './modules/NewsPage';
import { PageAchievements } from './modules/AchievementsPage';
import { PageMenuHandbook } from './modules/MenuHandbook';
import {
    PageHandbookAbout,
    PageHandbookEmployee, PageHandbookGrowth, PageHandbookReferral, PageHandbookVacation,
    PageHandbookWorkFormat,
    PageHandbookWorth
} from './modules/HandbookPage';
import React, {useEffect, useState} from "react";
import {PageTools} from "./modules/ToolsPage";
import WithAuth from "./modules/auth/WithAuth";


function Root() {

    const [firstOpen, setFirstOpen] = useState(false);

    const tg = window.Telegram.WebApp;
    useEffect(() => {
        if(firstOpen === false){
            setFirstOpen(true);
        }
    }, []);

    useEffect(() => {
        try{
            tg.expand();

        } catch(e){}
    }, [firstOpen]);

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) =>
        {
            fetch(`/api/post/TelegramSession?session_key=${localStorage.getItem("session_key")}`, {
                method: 'POST',
                body: JSON.stringify({
                    session_is_active: false,
                }),
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("access_token")}`
                }
            }).then(r => {});
        });
    }, [localStorage.getItem("session_key")]);

    useEffect(() => {
        if (localStorage.getItem("session_user") === null){
            //window.location.replace('https://google.com');
        }
    }, [localStorage.getItem("session_user")]);

    return (
            <Routes>
                <Route path="/" element={<WithAuth><PageIndex /></WithAuth>} />
                <Route path="actual" element={<WithAuth><PageMenuActual /></WithAuth>} />
                <Route path="helpful" element={<WithAuth><PageMenuHelpful /></WithAuth>} />
                <Route path="helpful/tools" element={<WithAuth><PageMenuTools /></WithAuth>} />
                <Route path="helpful/employers" element={<WithAuth><PageMenuEmployers /></WithAuth>} />
                <Route path="helpful/knowledge" element={<WithAuth><PageMenuKnowlegde /></WithAuth>} />
                <Route path="helpful/help" element={<WithAuth><PageWorkInProgress /></WithAuth>} />
                <Route path="helpful/handbook" element={<WithAuth><PageMenuHandbook /></WithAuth>} />
                <Route path="helpful/handbook/about" element={<WithAuth><PageHandbookAbout /></WithAuth>} />
                <Route path="helpful/handbook/structure" element={<WithAuth><PageHandbookEmployee /></WithAuth>} />
                <Route path="helpful/handbook/worth" element={<WithAuth><PageHandbookWorth /></WithAuth>} />
                <Route path="helpful/handbook/work_format" element={<WithAuth><PageHandbookWorkFormat /></WithAuth>} />
                <Route path="helpful/handbook/vacation" element={<WithAuth><PageHandbookVacation /></WithAuth>} />
                <Route path="helpful/handbook/growth" element={<WithAuth><PageHandbookGrowth /></WithAuth>} />
                <Route path="helpful/handbook/referral" element={<WithAuth><PageHandbookReferral /></WithAuth>} />
                <Route path="Users" element={<WithAuth><PageProfile /></WithAuth>} />
                <Route path="Users/achievements" element={<WithAuth><PageAchievements /></WithAuth>} />
                <Route path="Users/edit" element={<WithAuth><PageProfileEdit /></WithAuth>} />
                <Route path="News" element={<WithAuth><PageNews /></WithAuth>} />
                <Route path="Tools" element={<WithAuth><PageTools /></WithAuth>} />
                <Route path="LawFinance" element={<WithAuth><PageLawFinance /></WithAuth>} />
                <Route path="error" element={<PageError/>} />
                <Route path="*" element={<Page404 />} />
            </Routes>
    );
}

const router = createBrowserRouter([
    { path: "*", element: <Root /> },
]);

export default function App(){
    return <RouterProvider router={router}/>
}