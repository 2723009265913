import {useEffect, useState} from "react";
import {InfoDropdownField, PhotoElement, PostContent, PostInfo, SectionPostContent, VideoElement} from "./Elements";
import {useSearchParams} from "react-router-dom";
import authFetch from "./auth/authFetch";

function Post({post}){
    const [content, setContent] = useState([]);
    const [date, setDate] = useState(null);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        try{
            tg.BackButton.show();
            tg.MainButton.isVisible = true;
            tg.MainButton.color = '#007AFF';
            tg.MainButton.textColor = '#ffffff';
            tg.MainButton.setText(post.law_attachment_title ? post.law_attachment_title : "Открыть ссылку");
            tg.MainButton.onClick((() => window.open(post.law_attachment_url)));
            tg.BackButton.onClick((() => window.location.assign(`/helpful/knowledge#${post.law_id}`)));
        }catch (e){ console.log(e) }
        post.sublaw.map(obj => setContent(prevState => [...prevState, {title: `Пункт ${post.sublaw.indexOf(obj)+1}`,
            content:obj.sublaw_title, link: obj.sublaw_id}]));
    }, [])
    useEffect(() => {
        document.title = post.law_title;
        setDate(` | ${(new Date(post.law_creation_date)).toLocaleString("ru-RU", { year: 'numeric',
            month: 'numeric',
            day: 'numeric',})}`);

    }, [post]);
    return(
        <>
            <PostInfo title={post.law_title} subtitle={post.law_subtitle ? post.law_subtitle : "Полезные материалы"}/>
            <PhotoElement image={post.law_photo_url}/>
            <PostContent content={post.law_content}/>
            {content && <InfoDropdownField image='/src/images/icons/gray_paragraph.png' title="Содержание" content={content}/>}
            {post.law_video_url && <VideoElement video={post.law_video_url}/>}
            {post.sublaw && post.sublaw.map(obj => <SectionPostContent content={obj.sublaw_content} title={obj.sublaw_title} id={obj.sublaw_id}
                                                                       button={obj.sublaw_attachment_url}
                                                                       button_title={obj.sublaw_attachment_title} photo={obj.sublaw_photo_url}/>)}
            {post.user && post.user.map((obj) => <InfoDropdownField image='/src/images/icons/gray_phone.png' title={obj.full_name}
                                                                    content={[{title: "Телефон", content:obj.phone, link:null},
                                                                        {title: "Telegram", content:obj.username, link:null},
                                                                        {title: "Почта", content:obj.user_email, link:null}]}/>)}
        </>
    );
}


export function PageLawFinance() {
    const [post, setPost] = useState(null);
    const [params, setParams] = useSearchParams();
    useEffect(()=>{
        document.title = 'Новость';
        authFetch(`/api/get/LawFinance?law_id=${params.get('id')}`).then(res=>
            res.json()).then(data => setPost(data.result[0]));
        try{}catch(e){}
    },[]);
    return(
        <div className="main-block">
            {post && <Post post={post}/>}
        </div>
    );
}