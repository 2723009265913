import React, { useState, useEffect } from 'react';

import {PreloaderPage} from '../Preloaders';

import { getToken, setToken, removeToken, isExpired } from './token';
import {auth} from './api';

type Props = {
    children: React.ReactNode;
};

export default function WithAuth(props: Props){
    const { children } = props;
    const [isTokenFetchingActive, setTokenFetchingStatus] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                removeToken();

                const access_token = await auth();

                setToken(access_token);
                setIsAuthenticated(true);
                setTokenFetchingStatus(false);
            } catch (err) {
                const msg =
                    err instanceof Error ? err.message : 'Unknown Error: api.get.token';

                // реализуем утилитарное предупреждение для пользователя
                // eslint-disable-next-line no-alert
                alert(
                    `Неудалось загрузить токен доступа. Напишите об этом разработчику, тг - @n1ghtcore194. Ошибка: ${msg}`
                );

                window.location.assign(
                    `/error?from=${window.location.pathname}`
                );
            }
        };

        if (isTokenFetchingActive) {
            const token = getToken();

            if (token && !isExpired(token.timeStamp)) {
                setIsAuthenticated(true);
                setTokenFetchingStatus(false);
            } else {
                fetchToken();
            }
        }
    }, [isTokenFetchingActive]);

    const renderContent = () => {
        return isAuthenticated ? children : null;
    };

    return <div>{isTokenFetchingActive ? <PreloaderPage /> : renderContent()}</div>;
}