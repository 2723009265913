import '../css/preloader.min.css';
import '../css/elements.min.css';
import {IconElement} from "./Elements";

export function PreloaderPage() {
    return(
        <div className="page-preload preload">
        </div>
    );
}
export function PreloadCardElement(){
    return (
        <div className="card-menu">
            <div className="card-menu-content">
                <div className="card-menu-content-block">
                    <div className="preload-elem-img preload">
                    </div>
                    <div className="card-menu-content-block-text">
                        <div className="preload-elem-title preload"></div>
                        <div className="preload-elem-subtitle preload"></div>
                    </div>
                </div>
                <div className="card-menu-content-drill">
                    <img src="/src/images/icons/arrow.svg" alt=""/>
                </div>
            </div>
        </div>
    );
}

export function PreloaderMenu() {
    return (
        <div className="card-menu-container">
            <PreloadCardElement />
            <PreloadCardElement />
            <PreloadCardElement />
            <PreloadCardElement />
            <PreloadCardElement />
            <PreloadCardElement />
        </div>
    );
}