// IMPORTING STYLES
import '../css/base.min.css';
import '../css/index.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// IMPORTING ELEMENTS
import {useEffect, useState} from "react";
import {Carousel} from "react-bootstrap";
import {CardElement} from "./Elements";
import Cookies from 'universal-cookie';
import authFetch from "./auth/authFetch";


function EventContainer(){
    const [events, setEvents] = useState(null);
    const cookie = new Cookies();
    const authHeaders = new Headers();
    authHeaders.set("Authorization", `Bearer ${cookie.get("access_token")}`);
    useEffect(()=>{
        authFetch(`/api/get/News?is_actual=true&schematic=true`).then(res=>
            res.json()).then(data => setEvents(data.result));
    }, []);
    return (
        <div className="event-container">
            <style type="text/css">
                {`.carousel-indicators [data-bs-target]{width: 15px;}
                .carousel-control-prev-icon, .carousel-control-next-icon{
                    display: none;
                }
                `}
            </style>
            <Carousel touch={true} interval={10000} slide={true}>
                {events ? events.map(obj => (
                    <Carousel.Item onClick={() => window.location.assign(`/News?id=${obj.id}`)}>
                        <div className="event-image-container"> <img className="event-image-background" src={obj.photo_url} alt=""/> </div>
                        <Carousel.Caption>
                            {obj.is_visible && <div className="event-title">{obj.title}</div>}
                            {obj.is_visible && <div className="event-description">
                                <div className="event-description-type">{obj.subtitle}</div>
                            </div>}
                        </Carousel.Caption>
                    </Carousel.Item>
                )) :
                    <Carousel.Item onClick={() => window.location.assign(`/News`)}>
                        <div className="event-image-container-preload preload">
                        </div>
                        <Carousel.Caption>
                            <div className="event-title"> </div>
                            <div className="event-description">
                                <div className="event-description-type"> </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>}
            </Carousel>
        </div>
    );
}


function HeaderMenu() {
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        try{
            authFetch(`/api/get/Users?telegram_id=${localStorage.getItem("session_user")}`).then(res=>
             res.json()).then(data => setCurrentUser(data.result[0]));
        }catch(err){
            setCurrentUser({full_name: 'First Name', role: {role_name: 'Test'}})
            console.log(err);
        }
    }, []);
    return (
        <div className="header">
            <div className="header-logo" onClick={() => window.location.assign('/')}>
                <img src="/src/images/logo/logo_brick.svg" alt=""/>
            </div>
            {currentUser && <ProfileInfo name={currentUser.full_name.split(' ')[0]} role={currentUser.role.role_name}
                         image={currentUser.profile_photo ? currentUser.profile_photo : '/src/icons/logo192.png'}
                                         id={currentUser.user_id}/>}
        </div>
    );
}

function ProfileInfo({name, role, image, path, id}) {
    return (
        <div className="header-profile" onClick={()=> window.location.assign(`/Users?id=${id}`)} >
            <div className="header-profile-info">
                <div className="header-profile-info-name">{name}</div>
                <div className="header-profile-info-role">{role}</div>
            </div>
            <div className="header-profile-logo">
                <img className="header-profile-logo-photo" src={image} alt=''/>
            </div>
        </div>
    );
}

export function PageIndex() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = 'Главная';
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Закрыть");
        tg.MainButton.onClick(() => tg.close());
        tg.BackButton.show();
        tg.BackButton.onClick(() => tg.close());
    }, []);
    return (
        <div className="main-block">
            <HeaderMenu/>
            <EventContainer/>
            <CardElement image='/src/images/icons/helpful.png' title='Полезное'
                         subtitle='О команде и работе' path='/helpful' id='helpful'/>
            <CardElement image='/src/images/icons/actual.png' title='Актуальное'
                         subtitle='Новости и ивенты' path='/actual' id='actual'/>
        </div>
    );
}