import '../css/whiteleads.css'
import {useEffect, useState} from "react";
import {Carousel} from "react-bootstrap";
import {IconElement} from "./Elements";
import authFetch from "./auth/authFetch";

function StoryAbout() {
    const [index, setIndex] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState("#DADCFF");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("40");
    function handleSelect(selectedIndex){
        setIndex(selectedIndex);
        switch (selectedIndex) {
            case 0:
                setFontSize("40");
                setBackgroundColor("#DADCFF");
                setFontColor("#000000");
                break;
            case 1:
                setFontSize("40");
                setBackgroundColor("#FFFFFF");
                setFontColor("#000000");
                break;
            case 2:
                setFontSize("25");
                setBackgroundColor("#5863F8");
                setFontColor("#FFFFFF");
                break;
            case 3:
                setFontSize("25");
                setBackgroundColor("#DADCFF");
                setFontColor("#000000");
                break;
            case 4:
            case 5:
                setFontSize("40");
                setBackgroundColor("#F5F5F5");
                setFontColor("#000000");
                break;
            case 6:
                setFontSize("35");
                setBackgroundColor("#131313");
                setFontColor("#FFFFFF");
                break;
            case 7:
                setFontSize("35");
                setBackgroundColor("#131313");
                setFontColor("#FFFFFF");
                break;
            case 8:
                setFontSize("16");
                setBackgroundColor("#FFFFFF");
                setFontColor("#000000");
                break;
            case 9:
                setFontSize("32");
                setBackgroundColor("#FFFFFF");
                setFontColor("#000000");
                break;
            case 10:
                setFontSize("27");
                setBackgroundColor("#5863F8");
                setFontColor("#FFFFFF");
                break;
            case 11:
                setFontSize("40");
                setBackgroundColor("#CCF");
                setFontColor("#000000");
                break;
            case 12:
                setFontSize("40");
                setBackgroundColor("#131313");
                setFontColor("#FFFFFF");
                break;
            case 13:
                setFontSize("18");
                setBackgroundColor("#DADCFF");
                setFontColor("#000000");
                break;

        }
    }

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000} slide={false} onSelect={handleSelect} activeIndex={index}>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">handbook</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-first-page">
                            <div className="story-title auto-height">Как работаем <br/> в Whiteleads</div>
                            <div className="story-first-page-img">
                                <img className="story-footer-img arrow_down_left"
                                     src="/src/images/handbook/arrow_down_left.png"
                                     alt=""/>
                                <img className="story-footer-img settings" src="/src/images/handbook/settings.png"
                                     alt=""/>
                                <img className="story-footer-img arrow_down_right"
                                     src="/src/images/handbook/arrow_down_right.png" alt=""/>
                                <img className="story-footer-img user" src="/src/images/handbook/user.png" alt=""/>
                            </div>

                        </div>
                        <img className="story-description-logo" src="/src/images/logo/logo_mini.svg" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">о нас</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">WL — агентство мобильного маркетинга с R&D подходом</div>
                        <img className="story-footer-img RND" src="/src/images/handbook/R&D.png" alt=""/>>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">клиенты</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">Мы — партнёры крупнейших российских и европейских брендов</div>
                        <img className="story-footer-img clients" src="/src/images/handbook/clients.png" alt=""/>
                        <img className="story-description-logo reverse" src="/src/images/logo/wle_white.png" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">история</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">Как мы запустили агентство мобильного маркетинга в феврале 2022,
                            выжили и сформировали эффективную бренд-платформу
                        </div>
                        <div className="story-history">
                            <div className="read-title history-read">
                                <img className="story-history-read" src="/src/images/handbook/read.png" alt=""/>
                                <img className="story-history-arrow" src="/src/images/handbook/arrow_history.png"
                                     alt=""/>
                            </div>

                            <div className="about-button story-history-button"
                                 onClick={() => window.open("https://vc.ru/u/3105078-whiteleads")}><img
                                src="/src/images/handbook/vc.png" alt=""
                                className="vc"/>
                            </div>
                            <img className="story-history-screenshot" src="/src/images/handbook/history_screenshot.png" alt=""/>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">кейсы</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="case-container first">
                            <div className="case-container-award">
                                <div className="read-title case-read">
                                    <img className="story-history-read" src="/src/images/handbook/read.png" alt=""/>
                                    <img className="story-history-arrow" src="/src/images/handbook/arrow_history.png"
                                         alt=""/>
                                </div>
                                <img src="/src/images/handbook/cup.png" alt="" className="case-cup"/>
                                <div className="case">
                                    <div className="case-info">
                                        <div className="case-info-name">Joom</div>
                                        <div className="case-info-stats">
                                            <div className="case-info-stats-stat">3,4 млн установок</div>
                                            <div className="case-info-stats-stat">132 тыс. покупателей</div>
                                        </div>
                                    </div>
                                    <div className="case-awards">
                                        <div className="case-award-block"
                                             onClick={() => window.open("https://adindex.ru/case/2024/05/14/322559.phtml")}>
                                            <div className="case-award-coin">
                                                <img src="/src/images/handbook/gold_coin.png"
                                                     className="case-award-coin-1"
                                                     alt=""/>
                                                <img src="/src/images/handbook/silver_coin.png"
                                                     className="case-award-coin-2" alt=""/>
                                            </div>
                                            <div className="case-award-info">Silver Mercury 2024 ↗</div>
                                        </div>
                                        <div className="case-award-block"
                                             onClick={() => window.open("https://tagline.ru/cases/keys-whiteleads--4--3-mln--poljzovateley-dlya-mobiljnogo-prilozheniya-marketpleysa/")}>
                                            <div className="case-award-coin">
                                                <img src="/src/images/handbook/gold_coin.png"
                                                     className="case-award-coin-1"
                                                     alt=""/>
                                                <img src="/src/images/handbook/silver_coin.png"
                                                     className="case-award-coin-2" alt=""/>
                                                <img src="/src/images/handbook/silver_coin.png"
                                                     className="case-award-coin-3" alt=""/>
                                                <img src="/src/images/handbook/silver_coin.png"
                                                     className="case-award-coin-4" alt=""/>
                                                <img src="/src/images/handbook/silver_coin.png"
                                                     className="case-award-coin-5" alt=""/>
                                            </div>
                                            <div className="case-award-info">Tagline 2023 ↗</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="case">
                                <div className="case-info">
                                    <div className="case-info-name">LOOKY</div>
                                    <div className="case-info-stats">
                                        <div className="case-info-stats-stat">340 тыс. установок</div>
                                        <div className="case-info-stats-stat">+75% вовлечённость</div>
                                    </div>
                                </div>
                                <div className="case-awards">
                                    <div className="case-award-block"
                                         onClick={() => window.open("https://winners.eplus.marketing/2024/spefial-nye-nominafii/biznes-vyzovy/zapusk-novogo-produkta-ili-uslugi/looky-pervaya-sofset-s-ai-dlya-sozdaniya-kontenta")}>
                                        <div className="case-award-coin">
                                            <img src="/src/images/handbook/silver_coin.png"
                                                 className="case-award-coin-1" alt=""/>
                                        </div>
                                        <div className="case-award-info">E-plus 2024 (ex. Effie) ↗</div>
                                    </div>
                                    <div className="case-award-block"
                                         onClick={() => window.open("https://tagline.ru/cases/keys-whiteleads-i-looky--365-tys--poljzovateley-dlya-socialjnoy-seti-s-pomoschjyu-ai-v-reklame/")}>
                                        <div className="case-award-coin">
                                            <img src="/src/images/handbook/silver_coin.png"
                                                 className="case-award-coin-1" alt=""/>
                                            <img src="/src/images/handbook/bronze_coin.png"
                                                 className="case-award-coin-2" alt=""/>
                                        </div>
                                        <div className="case-award-info">Tagline 2023 ↗</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">кейсы</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-containe">
                        <div className="case-container">
                            <div className="case">
                                <div className="case-info">
                                    <div className="case-info-name">Lamoda</div>
                                    <div className="case-info-stats">
                                        <div className="case-info-stats-stat">375 тыс. CTA установок</div>
                                        <div className="case-info-stats-stat">12 тыс. покупок (7 дней)</div>
                                    </div>
                                </div>
                                <div className="case-awards">
                                    <div className="case-title">6 мес.</div>
                                    <img className="case-img lamoda" src="/src/images/handbook/sneaker.png" alt=""/>
                                </div>
                            </div>
                            <div className="case">
                                <div className="case-info">
                                    <div className="case-info-name">Перекрёсток</div>
                                    <div className="case-info-stats">
                                        <div className="case-info-stats-stat">78 тыс. установок</div>
                                        <div className="case-info-stats-stat">5 тыс. уник. покупок</div>
                                    </div>
                                </div>
                                <div className="case-awards">
                                    <div className="case-title">11 мес.</div>
                                    <img className="case-img perek" src="/src/images/handbook/apple.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">как работаем</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title-subcontainer">
                            <div className="story-title auto-height">Особенности агентства</div>
                            <div className="story-subtitle">Как мы работаем<br/>с рекламодателями?</div>
                        </div>
                        <div className="how-work shield">
                            <div className="how-work-description">Наши клиенты ничем не рискуют и платят только
                                за результат. Мы запускаем CPI<sup>1</sup>, CPA<sup>2</sup>,
                                RevShare<sup>3</sup> performance кампании
                            </div>
                            <div className="how-work-subdescription"><sup>1</sup>cost per install — оплата за
                                установку<br/>
                                <sup>2</sup>cost per action — оплата за действие<br/>
                                <sup>3</sup>revenue share — % от прибыли
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">как работаем</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title-subcontainer">
                            <div className="story-title auto-height">Особенности агентства</div>
                            <div className="story-subtitle">Как мы работаем<br/>с рекламодателями?</div>
                        </div>
                        <div className="how-work advertiser">
                            <div className="how-work-description ad">Наши клиенты всегда могут увидеть в точности,
                                сколько покупок или других действий было совершено с рекламы медиа байеров
                            </div>
                            <img className="how-work-subimage" src="/src/images/handbook/how_work.png" alt=""/>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">креативы</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">Создаём баннеры, motion и видео-ролики и нативно озвучиваем их для
                            разных гео на разных языках. Наша фишка — создание нативного UGC контента,
                            где мы показываем настоящие эмоции, а не идеальную картинку,
                            чтобы пользователю захотелось скачать, купить, заказать продукт клиента.
                            Такие креативы мы продвигаем в социальных сетях.
                        </div>
                        <img className="story-footer-img creative_video" src="/src/images/handbook/creative_video.png" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">креативы</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">Какие источники
                            мы используем
                            для продвижения
                            креативов?
                        </div>
                        <img className="story-footer-img creative_source" src="/src/images/handbook/creative_source.png"
                             alt=""/>
                        <div className="story-description">Используем социальные сети, кабинетный in-app и
                            программатик.</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">позиционирование</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">Whiteleads — агентство
                            мобильного маркетинга
                        </div>
                        <div className="story-description position">
                            <div className="story-description-p">
                                <img src="/src/images/handbook/mobile.png" className="story-description-mobile" alt=""/>
                                С помощью R&D подхода мы обеспечиваем максимальную эффективность продвижения приложений,
                                помогаем раскрыть их коммерческий потенциал и достичь самых амбициозных маркетинговых
                                целей.
                            </div>
                            <div className="story-description-p">
                                <img src="/src/images/handbook/perfomance.png" className="story-description-performance"
                                     alt=""/>
                                Мы снимаем уникальные UGC-креативы, используем оптимальные источники качественного
                                трафика, запускаем CPA кампании для рентабельного performance-продвижения приложений по
                                всему миру.
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">миссия</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container mission">
                        <div className="story-title-mission">Помогать<br/>
                            бизнесу расти,<br/>
                            чтобы бизнес делал<br/>
                            жизнь <img className="mission-img" src="/src/images/handbook/heart.png" alt=""/> лучше
                        </div>
                        <img src="/src/images/handbook/vector.png" className="mission-vector" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">стратегический план</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="plan-container">
                                <img className="plan" src="/src/images/handbook/plan.png"
                                 alt=""/>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">где говорим о себе</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <p className="story-title click-me-elem">Рассказывай о том, где работаешь, знакомым и друзьям ;)
                        </p>
                        <div className="story-description about">
                            <div className="about-container">
                                <div className="about-button" onClick={() => window.open("https://whiteleads.ru/")}><img
                                    src="/src/images/handbook/web.png" alt=""
                                    className="web"/></div>
                                <div className="about-button tg"
                                     onClick={() => window.location.assign("https://t.me/whiteleads")}>Телеграм
                                    агентства
                                </div>

                                <div className="about-button"
                                     onClick={() => window.open("https://adindex.ru/news/releases/2024/04/26/322355.phtml?_sp=whiteleads")}>
                                    <img src="/src/images/handbook/adindex.png" alt="" className="adindex"/></div>
                            </div>
                            <div className="about-container">
                                <div className="about-button rocknroll"
                                     onClick={() => window.location.assign("https://t.me/joinwhiteleads")}>Телеграм
                                    команды
                                </div>
                                <div className="about-button"
                                     onClick={() => window.open("https://vc.ru/u/3105078-whiteleads")}><img
                                    src="/src/images/handbook/vc.png" alt=""
                                    className="vc"/></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function StoryEmployee() {
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("16");
    const [founders, setFounders] = useState(null);
    const [heads, setHeads] = useState(null);
    useEffect(()=>{
        authFetch("/api/get/Team?id=1").then(res => res.json()).then(data => {setFounders(data.result[0].team_member)});
        authFetch("/api/get/Users?user_id=5").then(res => res.json()).then(data => {setHeads(data.result[0])});
        console.log(founders);
    }, [])

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000} slide={false}>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">наши фаундеры</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-description">
                            <div className="founders-block">
                                {founders && founders.map(obj => <div className="founders-card" onClick={() => window.location.assign(`/Users?id=${obj.user_id}`)}>
                                    <div className="founders-img-container">
                                        <img src={obj.profile_photo} alt="" className="founders-img"/>
                                    </div>
                                    <div className="founders-title">
                                        <div className="founders-name">{obj.full_name}</div>
                                        <div className="founders-structure">{obj.role.role_name}</div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">структура отделов</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                            <div className="story-description heads">
                                {founders && founders.map(item => <div className="card-menu head-card"
                                                                       onClick={() => window.location.assign(`/helpful/employers?structure=${item.structure.structure_id}`)} id={item.user_id}>
                                    <div className="card-menu-content">
                                        <div className="card-menu-content-block">
                                            <IconElement image={item.profile_photo} isElement={true}/>
                                            <div className="card-menu-content-block-text">
                                                <div
                                                    className="card-menu-content-block-text-title">{item.structure.structure_name}</div>
                                                <div
                                                    className="card-menu-content-block-text-subtitle">{item.full_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                {heads && <div className="employee-lead-container">
                                    <div className="card-menu head-card" id={heads.user_id}>
                                        <div className="card-menu-content"
                                             onClick={() => window.location.assign(`/helpful/employers?structure=${heads.structure.structure_id}`)}>
                                            <div className="card-menu-content-block">
                                                <IconElement image={heads.profile_photo} isElement={true}/>
                                                <div className="card-menu-content-block-text">
                                                    <div
                                                        className="card-menu-content-block-text-title">{heads.structure.structure_name}</div>
                                                    <div
                                                        className="card-menu-content-block-text-subtitle">{heads.full_name}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="employee-lead"
                                             onClick={() => window.location.assign("/helpful/employers")}>Орг.
                                            Cтруктура ↗
                                        </div>
                                    </div>

                                </div>}

                            </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function StoryWorth() {
    const [backgroundColor, setBackgroundColor] = useState("#DADCFF");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("16");
    const [worth, setWorth] = useState(null);
    useEffect(() => {
        authFetch("/api/get/WhiteleadsWorth").then(res => res.json()).then(
            data => setWorth(data.result));
    }, [])

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000} slide={false}>
                {worth && worth.map(obj => <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">ценности</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container mission">
                        <div className="story-title-id">{obj.worth_id}</div>
                        <div className="story-title-worth">
                            {obj.worth_title}
                        </div>
                        <img src={obj.worth_photo_url} className="worth-img" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>)}
            </Carousel>
        </div>
    );
}


function StoryWorkFormat() {
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("30");

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000} slide={false}>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">где работаем</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-title">У нас есть два офиса,
                            а ещё ты можешь работать удалённо!
                        </div>
                        <div className="story-description">
                            <img src="/src/images/handbook/pointer.png" className="pointer" alt=""/>
                            Питер, ул. Чайковского, 79<br/>
                            Москва, ул. Митинская, 16

                            <img src="/src/images/handbook/office.jpg" className="office-img" alt=""/>
                        </div>


                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">когда мы работаем</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="when-we-work-container">
                            <div className="story-block">
                                <div className="story-title-contaier">
                                    <div className="story-title when-work">Для всех</div>
                                    <div className="story-description">Необходимо быть
                                        на связи 11 до 19 по мск 5/2
                                    </div>
                                </div>
                                <div className="story-title-contaier">
                                    <div className="story-title when-work">Для байеров</div>
                                    <div className="story-description">Выстраивают свою работу
                                        и график сами, исходя из собственных целей (с соблюдением условия выше)
                                    </div>
                                </div>
                            </div>
                            <div className="story-title-contaier">
                                <div className="story-title when-work description">*</div>
                                <div className="story-description cursive">
                                    Коллеги, при этом мы не против если вы начнёте работать
                                    раньше или позже,
                                    считая это эффективным.
                                    <br/><br/>
                                    Мы ценим ответственный самостоятельный подход, и взамен готовы предоставить
                                    комфортные
                                    условия.
                                </div>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">когда деньги</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-payday">
                            <div className="story-payday-img"><img src="/src/images/handbook/dollar.png" alt=""
                                                                   className="dollar-img"/>
                                <img src="/src/images/handbook/line.png" className="line-img" alt=""/>

                            </div>
                            <div className="story-block payday-gap">
                                <div className="story-description">Выплата фикс-а происходит до 5 числа каждого месяца,
                                    если
                                    5-е
                                    число выпадает на выходной, то выплачивается в рабочий день перед 5-м числом,
                                    например,
                                    5-е число выпало на воскресенье, значит выплата будет 3-го числа в пятницу.
                                </div>
                                <div className="story-description">Выплата бонусной части происходит через месяц после
                                    отчётного до 10-го числа.
                                </div>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">ежемесячный звонок</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-description">
                            <div className="story-call-description-no">
                                Каждый месяц в<span className="monthly-call"> первую </span>пятницу после 5-го числа мы проводим общекомандный звонок,
                                на котором
                                обсуждаем итоги прошлого месяца, ставим цели на следующий, и выделяем топеров.
                                <br/> <br/>
                                На нём обязательно быть, и желательно
                                с включенной камерой :)
                            </div>
                        </div>
                        <div className="story-call-date">
                            <div className="story-call-date-elem">
                                <div className="story-call-date-elem-number">3</div>
                                <div className="story-call-date-elem-container"></div>
                            </div>
                            <div className="story-call-date-elem">
                                <div className="story-call-date-elem-number">4</div>
                                <div className="story-call-date-elem-container"></div>
                            </div>
                            <div className="story-call-date-elem">
                                <div className="story-call-date-elem-number">5</div>
                                <div className="story-call-date-elem-container">
                                    <img alt="" className="story-call-date-elem-img" src="/src/images/handbook/meet.png"/>
                                </div>
                            </div>
                            <div className="story-call-date-elem">
                                <div className="story-call-date-elem-number">6</div>
                                <div className="story-call-date-elem-container"></div>
                            </div>
                        </div>
                        <div className="story-call">
                            <div className="story-call-description">
                                Все прошедшие звонки хранятся в Slack в канале #whiteleads
                                в закрепе.
                            </div>
                            <img src="/src/images/handbook/camera.png" alt="" className="camera-img"/>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function StoryVacation() {
    const [index, setIndex] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("30");
    const tg = window.Telegram.WebApp;

    function handleSelect({index}) {
        setIndex(index);
        if (index === 1) {
            tg.MainButton.onClick(() => window.open("https://whiteleads.slack.com/archives/C0702H3QUQ1"));
            tg.MainButton.setText("Ссылка в Slack");
        } else {
            tg.MainButton.onClick(() => window.location.assign('/helpful/handbook/'));
            tg.MainButton.setText("назад");
        }
    }

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={50000000} slide={false} onSelect={handleSelect} activeIndex={index}>
                <Carousel.Item>
                    <div className="story-image-container">
                    <div className="story-header-container">
                            <div className="story-image-title">как отдохнуть</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <p className="chill">
                            У нас нет минимального и максимального количества дней для отдыха в году.
                            <br/> <br/>
                            Ты в любой момент можешь попросить
                            уйти отдыхать на любое количество дней — главное, показывай результат. Если ты
                            перевыполняешь планы, отдыхай хоть
                            через день))
                        </p>
                        <img src="/src/images/handbook/chill.png" className="office-img" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">как отдохнуть</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-block star-img">
                            <div className="story-point">
                                <div className="story-point-pointer">1</div>
                                <div className="story-point-description">Утверди отдых и его даты с прямым руководителем за 2–3 недели</div>
                            </div>
                            <div className="story-point">
                                <div className="story-point-pointer">2</div>
                                <div className="story-point-description">Передай свои дела коллегам</div>
                            </div>
                            <div className="story-point">
                                <div className="story-point-pointer">3</div>
                                <div className="story-point-description">Сообщи о своём отпуске в канале #vacations в Slack</div>
                            </div>
                            <div className="story-point">
                                <div className="story-point-pointer">4</div>
                                <div className="story-point-description">Поставь в Slack иконку 🏝️
                                    отдыха в статус</div>
                            </div>
                        </div>

                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function StoryGrowth() {
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("30");

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000} slide={false}>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">кто такой прямой руководитель?</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-description">Пока без текста, но он очень скоро появится! :)</div>
                        <img src="/src/images/handbook/boss.png" className="office-img" alt=""/>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">карьерный рост
                            </div>
                            <img src="/src/images/handbook/for_buyers.png" alt="" className="for_buyers-img"/>
                        </div>

                    </div>
                    <Carousel.Caption className="story-title-container">
                        <img src="/src/images/handbook/grades.png" alt="" className="grades-img"/>
                        <div className="story-description">Переход на совсем другое направление зависит от твоей
                            позиции.
                            <br/><br/>
                            А для движения вверх по карьере нужно работать и… работать! А главное — с хорошим KPI.
                            <br/><br/>
                            Руководители и HR регулярно оценивают сотрудников, так что ничто не остается без внимания. А
                            ещё ты всегда можешь написать им с дельными предложениями по поводу своей работы и работы
                            компании.
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function StoryReferral() {
    const [backgroundColor, setBackgroundColor] = useState("#F5F5F5");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontSize, setFontSize] = useState("30");

    return (
        <div className="story-container">
            <Styling background={backgroundColor} fontColor={fontColor} fontSize={fontSize}/>
            <Carousel touch={false} interval={5000000} slide={false}>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">реферальная программа</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-description">Узнать о новых вакансиях ты можешь
                            на ежемесячных звонках или в Телеграм канале команды.
                            <br/><br/>
                            Чтобы предложить своего друга в качестве кандидата, просто напиши любому
                            HR в личку.
                        </div>
                        <div className="referral-container">
                            <img src="/src/images/handbook/employee.png" alt="" className="employee-img"/>
                            <div className="referral-block">
                                <div className="referral-headers">
                                    <div className="referral-headers-item first-referral">Грейд сотрудника</div>
                                    <div className="referral-headers-item">Бонус</div>
                                </div>
                                <div className="referral-main-container">
                                    <div className="referral-info-container">
                                        <div className="referral-info">
                                            <div className="referral-info-grade first-referral">Junior</div>
                                            <div className="referral-info-reward">10 000 ₽</div>
                                        </div>
                                        <div className="referral-info">
                                            <div className="referral-info-grade first-referral">Middle</div>
                                            <div className="referral-info-reward">15 000 ₽</div>
                                        </div>
                                        <div className="referral-info">
                                            <div className="referral-info-grade first-referral">Senior</div>
                                            <div className="referral-info-reward">20 000 ₽</div>
                                        </div>
                                    </div>
                                    <div className="referral-subtitle">Выплата — спустя 2 месяца работы</div>
                                </div>


                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="story-image-container">
                        <div className="story-header-container">
                            <div className="story-image-title">реферальная программа</div>
                        </div>
                    </div>
                    <Carousel.Caption className="story-title-container">
                        <div className="story-description"></div>
                        <div className="referral-container buyers">
                            <img src="/src/images/handbook/buyers.png" alt="" className="buyers-img"/>
                            <img src="/src/images/handbook/pin.png" alt="" className="pin-img"/>
                            <div className="referral-block">
                                <div className="referral-headers">
                                    <div className="referral-headers-item first-referral">Позиция</div>
                                    <div className="referral-headers-item">Бонус</div>
                                </div>
                                <div className="referral-main-container">
                                    <div className="referral-info-container">
                                        <div className="referral-info">
                                            <div className="referral-info-grade first-referral">UA manager</div>
                                            <div className="referral-info-reward">20 000 ₽</div>
                                        </div>
                                    </div>
                                    <div className="referral-subtitle">Выплата — спустя 2 или 3 месяца работы, при выполнении KPI 20 000$ профита суммарно за 3 месяца и меньше</div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

function Styling({background, fontColor, fontSize}) {
    return (
        <style type="text/css">
            {`
                body{
                    background-color: ${background};
                }
                .story-image-title{
                    color: ${fontColor};
                    border: 1px solid ${fontColor};
                }
                .story-title{
                    color: ${fontColor};
                    font-size: ${fontSize}px;
                    line-height: calc(${fontSize}px*1.25)
                }
                .story-description{
                    color: ${fontColor};
                }
                .carousel {
                    width: 100vw;
                }
                .carousel-indicators [data-bs-target]{
                    width: 100%;
                    background-color: ${fontColor};
                    opacity: .3;
                }
                .carousel-indicators .active{
                    opacity: 1;
                }
                .carousel-indicators {
                    bottom: unset;
                    margin-right: 5%;
                    margin-left: 5%;
                    margin-bottom: 3%;
                }
                .carousel-control-prev-icon, .carousel-control-next-icon{
                    display: none;
                }
                .carousel-caption{
                    display: flex;
                    flex-direction: column;
                    right: 6%;
                    left: 6%;
                    bottom: 4vh;
                    height: 80vh;
                    justify-content: space-between;
                    padding-bottom: unset;
                }
                             
                @keyframes loader {
                    0%   { background-position: 100% 0; }
                    100% { background-position: 0 0; }
                }
                `}
        </style>
    )
}

function init(tg) {
    tg.BackButton.show();
    tg.MainButton.isVisible = true;
    tg.MainButton.color = '#007AFF';
    tg.MainButton.textColor = '#ffffff';
    tg.MainButton.setText("Назад");
    if(document.referrer === null){
        tg.MainButton.onClick((() => window.location.assign('/helpful/handbook/')));
        tg.BackButton.onClick((() => window.location.assign('/helpful/handbook/')));
    }
    else{
        const path = document.referrer.replace(/^[^:]+:\/\/[^/]+/, '').replace(/#.*/, '').replace(/\?.*/, '');
        (path !== '/Tools' && path !== '/Users' && path !== '/helpful/employers') && sessionStorage.setItem("ref", `${path}`);
        (path === '/helpful/employers' || path === "/Users") && sessionStorage.setItem("ref", `/helpful/handbook/`);
        tg.MainButton.onClick((() => window.location.assign(sessionStorage.getItem("ref"))));
        tg.BackButton.onClick((() => window.location.assign(sessionStorage.getItem("ref"))));
    }
}

export function PageHandbookAbout() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Об агентстве";
        init(tg);
    }, []);
    return (
            <div className="main-block">
                <StoryAbout/>
            </div>
    );
}

export function PageHandbookEmployee() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Сотрудники";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryEmployee/>
        </div>
    );
}

export function PageHandbookWorth() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Сотрудники";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryWorth/>
        </div>
    );
}

export function PageHandbookWorkFormat() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Формат работы";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryWorkFormat/>
        </div>
    );
}

export function PageHandbookVacation() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Отдых";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryVacation/>
        </div>
    );
}

export function PageHandbookGrowth() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Карьерный рост";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryGrowth/>
        </div>
    );
}

export function PageHandbookReferral() {
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = "Реферальная программа";
        init(tg);
    }, []);
    return (
        <div className="main-block">
            <StoryReferral/>
        </div>
    );
}