import '../css/profilePage.min.css'
import {useState, useEffect} from "react";
import {PhotoElement, Title, EditProfileForm} from "./Elements";
import authFetch from "./auth/authFetch";


function ProfileEdit({user}){
    const [curUser, setCurUser] = useState(user);
    const [selectedImage, setSelectedImage] = useState(curUser.profile_photo);
    const [fields, setFields] = useState([
        {title: 'День Рождения', type:'date', value: curUser.birth_date, name: 'birth_date'},
        {title: 'Работаю с', type:'date', value: curUser.starts_work, name: 'starts_work'},
        {title: 'Номер телефона', type:'tel', value: curUser.phone, name: 'phone', maxlength: 16},
        {title: 'Город', type:'text', value: curUser.city, name: 'city', maxlength: 16},
        {title: 'Увлечения', type:'text', value: curUser.hobbies, name: 'hobbies', maxlength: ''},
        {title: 'Профессиональный опыт', type:'text', value: curUser.experience, name: 'experience', maxlength: ''},
    ]);
    const tg = window.Telegram.WebApp;
    function handleChange(e){
        if(Object.keys(curUser).includes(e.target.name)){
            if(e.target.name !== "profile_photo"){
                setCurUser({...curUser, [e.target.name]: e.target.value});
            }
        }
    }

    function postData (){
        if(selectedImage instanceof Object ){
            const form = new FormData();
            form.append(
                "image", selectedImage);
            form.append("telegram_id", curUser.telegram_id);
            console.log(form);
            authFetch("/api/save/image", {
                method: "POST",
                body: form
            }).then(response => response.json()).then(data => {
                const updatedUser = { ...curUser, profile_photo: data.path };
                authFetch(`/api/post/Users?user_id=${updatedUser.user_id}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedUser)}).then(res=>res.json()).then(
                        data => {
                            alert("Данные сохранены!");
                            window.location.assign(document.referrer);
                        }
                ).catch((e) => {
                    alert(`Возникла ошибка!\nОписание ошибки - ${e}\nНапиши в телеграм @n1ghtcore194(https://t.me/n1ghtcore194), приложив скриншот ошибки`);
                    window.location.open("https://t.me/n1ghtcore194");
                    window.location.assign(document.referrer);
                });
            }).catch((e) => {
                alert(`Возникла ошибка!\nОписание ошибки - ${e}\nНапиши в телеграм @n1ghtcore194(https://t.me/n1ghtcore194), приложив скриншот ошибки`);
                window.location.open("https://t.me/n1ghtcore194");
                window.location.assign(document.referrer);
            });
        }
        else{
            authFetch(`/api/post/Users?user_id=${curUser.user_id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(curUser)}).then(res=>res.json()).then(
                data => {
                    alert("Данные сохранены!");
                    window.location.assign(document.referrer);
                }
            ).catch((e) => {
                alert(`Возникла ошибка!\nОписание ошибки - ${e}\nНапиши в телеграм @n1ghtcore194(https://t.me/n1ghtcore194), приложив скриншот ошибки`);
                window.location.open("https://t.me/n1ghtcore194");
                window.location.assign(document.referrer);
            });
        }
    }
    useEffect(() => {
        document.title = `Редактирование - ${curUser.full_name}`;
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.MainButton.setText("Сохранить");
        tg.BackButton.onClick((() => window.location.assign(`/Users?id=${user.user_id}`)));
        tg.MainButton.onClick(postData);
        return () => {
            tg.MainButton.offClick(postData);
        }
    }, []);

    useEffect(() => {
        tg.MainButton.onClick(postData);
        return () => {
            tg.MainButton.offClick(postData);
        }
    }, [curUser, selectedImage]);

    return(
        <>
            <Title title="Редактирование профиля"/>
            <PhotoElement image={selectedImage instanceof Object ? URL.createObjectURL(selectedImage) : selectedImage}/>
            <div className="edit-profile-section">
                <form className="edit-profile-form" onChange={handleChange}>
                    <div className="file-upload">
                        <img className="" src="/src/images/icons/image_upload.svg" alt=''/>
                        <label className="file-upload-lable">
                            <input className="file-upload-button" type='file' alt=''
                                   accept="image/png, image/jpeg, image/jpg" name="profile_photo"
                                   onChange={(event) => {
                                       setSelectedImage(event.target.files[0]);
                                   }}/>
                            Установить новое фото профиля
                        </label>
                    </div>
                    <EditProfileForm fields={fields}/>
                </form>
            </div>
        </>
    );
}

export function PageProfileEdit() {
    const [user, setUser] = useState(null);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = 'Пользователь';
        try {
            authFetch(`/api/get/Users?telegram_id=${tg.initDataUnsafe.user.id}`).then(res =>
                res.json()).then(data => {
                setUser(data.result[0]);
                setUser(prevUser => {
                    const {role, structure, telegram_role, team, achievements,
                        user_email, username, is_new, full_name, is_working, is_remote, ...perfUser } = prevUser;
                    return perfUser;
                });
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <div className="main-block">
            {user && <ProfileEdit user={user}/>}
        </div>
    );
}