import {PostInfo, PhotoElement, PostContent} from "./Elements";
import {useRef, useEffect, useState, useMemo} from "react";
import { useSearchParams} from "react-router-dom";
import authFetch from "./auth/authFetch";

function News({event}){
    const [date, setDate] = useState(null);
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        document.title = event.news_title;
        setDate(` | ${(new Date(event.news_date)).toLocaleString("ru-RU", { year: 'numeric',
            month: 'numeric',
            day: 'numeric',})}`);


    }, [event]);
    useMemo(()=>{
        try{
            if (event.news_url){
                tg.BackButton.show();
                tg.MainButton.isVisible = true;
                tg.MainButton.color = '#007AFF';
                tg.MainButton.textColor = '#ffffff';
                tg.MainButton.setText("Открыть ссылку");
                tg.MainButton.onClick((() => window.open(event.news_url)));
            }
            else{
                tg.BackButton.show();
                tg.MainButton.isVisible = true;
                tg.MainButton.color = '#007AFF';
                tg.MainButton.textColor = '#ffffff';
                tg.MainButton.setText("Назад");
                tg.MainButton.onClick((() => window.location.assign(`${document.referrer}#${event.news_id}`)));
            }

            tg.BackButton.onClick((() => window.location.assign(`${document.referrer}#${event.news_id}`)));
        }catch (e){ console.log(e) }
    }, [])
    return(
        <>
            <PostInfo title={event.news_title} subtitle={`${event.news_type}${date}`}/>
            <PhotoElement image={event.news_photo_url}/>
            <PostContent content={event.news_description}/>
        </>
    );
}


export function PageNews() {
    const [event, setEvent] = useState(null);
    const [params, setParams] = useSearchParams();
    useEffect(()=>{
        document.title = 'Новость';
        authFetch(`/api/get/News?news_id=${params.get('id')}`).then(res=>
            res.json()).then(data => setEvent(data.result[0]));
        try{}catch(e){}
    },[]);
    return(
        <div className="main-block">
            {event && <News event={event}/>}
        </div>
    );
}