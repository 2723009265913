import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {InfoDropdownSection, PostInfo} from "./Elements";
import {Page404} from "./PageCodeState";
import authFetch from "./auth/authFetch";

function Achievement({user}){
    const tg = window.Telegram.WebApp;
    useEffect(() => {
        tg.BackButton.show();
        tg.MainButton.isVisible = true;
        tg.MainButton.color = '#007AFF';
        tg.MainButton.textColor = '#ffffff';
        tg.BackButton.onClick((() => window.location.assign(`/Users?id=${user.user_id}`)));
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick((() => window.location.assign(`/Users?id=${user.user_id}`)));
    }, [])

    return(<>
        <div className="tool-title-container">
            <img className="tool-title-img" src="/src/images/icons/achievements.png" alt=''/>
            <PostInfo title="Достижения" subtitle={`${user.achievements.length} Достижения`}/>
        </div>
        {user.achievements.length > 0 ? user.achievements.map(achievement =>
            <div className="tool-content-container">
                <InfoDropdownSection title={achievement.achieve_title} content={achievement.achieve_content}/>
            </div>
        ) : <Page404 title="Достижения"/>}
    </>);
}


export function PageAchievements() {
    const tg = window.Telegram.WebApp;
    const [user, setUser] = useState(null);
    const [params, setParams] = useSearchParams();
    useEffect(() => {
        tg.BackButton.onClick((() => window.location.assign(document.referrer)));
        tg.MainButton.setText("Назад");
        tg.MainButton.onClick((() => window.location.assign(document.referrer)));
        document.title = 'Достижения';
        authFetch(`/api/get/Users?user_id=${params.get('id')}`).then(res=>
            res.json()).then(data => {
            setUser(data.result[0]);
        });
    }, [])
    return(
        <div className="main-block">
            {user && <Achievement user={user}/>}
        </div>
    );
}