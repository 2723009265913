import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Root';
import reportWebVitals from './reportWebVitals';
import {ErrorBoundary} from "react-error-boundary";
import {PageError} from "./modules/PageCodeState";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={<PageError />}>
        <App />
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
