import {isExpired} from "./token";

export async function auth(){
    const tg = window.Telegram.WebApp;
    if(process.env.NODE_ENV === "development"){
            localStorage.setItem('session_key', "c4816659205564f17b18bb98b3569fd527d5a67dd711b31ce5a4ff94365686ad");
            localStorage.setItem('session_user', "6869313030");
            await fetch("/api/session", {
                method: 'POST',
                body: JSON.stringify({
                    session_key: "c4816659205564f17b18bb98b3569fd527d5a67dd711b31ce5a4ff94365686ad",
                    session_user_id: "6869313030",
                    session_alive_time: 10000,
                    session_is_active: true,
                    session_device: window.navigator.userAgent.toString()
                }),
            });
            const authorize = await fetch("/api/auth", {
                method: 'POST',
                body: JSON.stringify({
                    telegram_id: "6869313030",
                    session_key: "c4816659205564f17b18bb98b3569fd527d5a67dd711b31ce5a4ff94365686ad"
                })
            });
            const response = await authorize.json();
            return await response.access_token;
        }
    else if (tg.initDataUnsafe.user) {
            localStorage.setItem('session_key', tg.initDataUnsafe.hash);
            localStorage.setItem('session_user', tg.initDataUnsafe.user.id);
            await fetch("/api/session", {
                method: 'POST',
                body: JSON.stringify({
                    session_key: String(tg.initDataUnsafe.hash),
                    session_user_id: String(tg.initDataUnsafe.user.id),
                    session_alive_time: 10000,
                    session_is_active: true,
                    session_device: window.navigator.userAgent.toString()
                }),
            });
            const authorize = await fetch("/api/auth", {
                method: 'POST',
                body: JSON.stringify({
                    telegram_id: String(tg.initDataUnsafe.user.id),
                    session_key: String(tg.initDataUnsafe.hash)
                })
            });
            const response = await authorize.json();
            return await response.access_token;
    }
}